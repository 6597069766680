.App {
  background-color: #000;
  overflow-x: hidden;
  position: relative;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 300px;
}

.overlay-box p {
  font-family: 'Ringbearer', serif;
  font-size: 1.5rem;
  color: #333;
}

.overlay-box button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.overlay-box button:hover {
  background-color: #555;
}

/* Video Section */
.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.video-container {
  width: 60vw;
  height: auto;
  transition: transform 0.1s ease-out;
  perspective: 1000px;
}

video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

/* Description Section */
.description-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.description-text {
  font-family: 'Ringbearer', serif;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  max-width: 600px;
}

/* Logo and Icons */
.logo {
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-height: 50px;
}

.right-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.icon {
  width: 50px;
  height: 50px;
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
}

.icon:hover {
  transform: scale(1.1);
  opacity: 0.8;
}


/* Particle Styling */
.particles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  z-index: 20; /* Above all other elements */
}

.particle {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: orange;
  border-radius: 50%;
  opacity: 0.9;
  animation: float 5s linear infinite, flicker 0.3s linear infinite;
  filter: blur(1px);
}

.particle:nth-child(odd) {
  background-color: #ff4500;
}

.particle:nth-child(even) {
  background-color: #ffa500;
}

@keyframes float {
  0% {
    transform: translate(0, 0) scale(0.8);
    opacity: 0.9;
  }
  50% {
    transform: translate(50vw, -50vh) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translate(-10vw, 10vh) scale(0.8);
    opacity: 0.9;
  }
}

@keyframes flicker {
  0%, 20%, 50%, 80%, 100% {
    opacity: 1;
  }
  10%, 30%, 60%, 90% {
    opacity: 0.6;
  }
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Below particles */
}

.overlay-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 300px;
}

.overlay-box p {
  font-size: 1.5rem;
  color: #333;
}

.overlay-box button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.overlay-box button:hover {
  background-color: #555;
}

/* Other styles remain the same */
